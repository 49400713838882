import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col } from 'react-bootstrap';
import './acercade_v2.css';
import Acercade_icon from '../../../assets/icons/acercade-icon.webp';
import Header_v2 from './header_v2';

const Acerca_de_v2 = () => {
  return (
    <div>
      <Header_v2 />
      <Container>
        <div className="green-bar">
          <Row>
            <h2 className="section-title">Acerca de</h2>
          </Row>
        </div>

        <Row>
          <Col>
            <div>
              <img className="acerca_de_figura" src={Acercade_icon} />
            </div>
          </Col>
          <Col xs={7}>
            <div className="section-content">
              <b>
                PredictGen tiene el objetivo de proveer una herramienta de análisis integral para pacientes con
                padecimientos crónicos no transmisibles relacionados con síndrome metabólico y con interacción entre
                factores genéticos y ambientales. A partir de la identificación de variantes genéticas, análisis de
                hábitos alimenticios y requerimientos nutrimentales, análisis bioquímicos y fisiológicos de pacientes,
                el algoritmo establece asociaciones entre el conjunto de características de cada paciente, identifica
                patrones o tendencias y sugiere una predicción de pronóstico individualizada.
              </b>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Acerca_de_v2;
