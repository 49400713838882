import React, { useState } from 'react';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notificationController } from '@app/controllers/notificationController';
import { DatosAntropometricosModel } from '@app/models/DatosAntropometricosModel';
import { doGuardarDatosAntropometricos } from '@app/api/slices/pacienteSlice';
import { getIdPaciente } from '@app/utils/localStorage.service';
import { Loading } from '@app/components/common/Loading';

const DatosAntropometricosForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingPage, setLoadingPage] = useState(false);

  // Initialize form state
  const [formValues, setFormValues] = useState<DatosAntropometricosModel>({
    id_medicion: 0,
    id_paciente: '',
    genero: '',
    peso: '',
    edad: '',
    estatura: '',
    imc: '',
    cintura: '',
    cadera: '',
    icc: '',
    grasa: '',
  });

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setLoadingPage(true);
    formValues.id_paciente = getIdPaciente().toString();
    dispatch(doGuardarDatosAntropometricos(formValues))
      .unwrap()
      .then((res) => {
        notificationController.success({
          message: t('Operación exitosa.'),
          description: t('Datos antropometricos guardados correctamente.'),
        });
        navigate('/analisis_datos_v2');
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  if (isLoadingPage) {
    return <Loading />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-container">
        <div className="form-column">
          <label htmlFor="peso">Peso (kg)</label>
          <input
            type="text"
            name="peso"
            value={formValues.peso}
            onChange={handleInputChange}
            placeholder="Ingrese su peso (kg)"
          />

          <label htmlFor="estatura">Estatura (cm)</label>
          <input
            type="text"
            name="estatura"
            value={formValues.estatura}
            onChange={handleInputChange}
            placeholder="Ingrese su estatura (cm)"
          />

          <label htmlFor="imc">Índice de masa corporal (IMC)</label>
          <input
            type="text"
            name="imc"
            value={formValues.imc}
            onChange={handleInputChange}
            placeholder="Ingrese su IMC"
          />
        </div>

        <div className="form-column">
          <label htmlFor="cadera">Circunferencia de cadera (cm)</label>
          <input
            type="text"
            name="cadera"
            value={formValues.cadera}
            onChange={handleInputChange}
            placeholder="Ingrese su circunferencia de cadera (cm)"
          />

          <label htmlFor="icc">Índice cintura cadera (ICC)</label>
          <input
            type="text"
            name="icc"
            value={formValues.icc}
            onChange={handleInputChange}
            placeholder="Ingrese su ICC"
          />

          <label htmlFor="grasa">Porcentaje de grasa (%)</label>
          <input
            type="text"
            name="grasa"
            value={formValues.grasa}
            onChange={handleInputChange}
            placeholder="Ingrese su porcentaje de grasa (%)"
          />
        </div>

        <div className="form-buttons">
          <button type="button" onClick={() => navigate('/analisis_datos_v2')}>
            Volver
          </button>
          <button type="submit" disabled={isLoading}>
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
};

export default DatosAntropometricosForm;
