import React from 'react';
import './frontend_v2.css';
import Header_v2 from './header_v2';
import DatosAntropometricosForm from './DatosAntropometricosForm';
import { Row } from 'antd';
import { Container } from 'react-bootstrap';

const Captura_Antropometrica: React.FC = () => {
  return (
    <div>
      <Header_v2 />
      <Container>
        <div className="green-bar">
          <Row>
            <h2 className="section-title">Captura de datos antropometricos</h2>
          </Row>
        </div>
        <DatosAntropometricosForm />
      </Container>
    </div>
  );
};

export default Captura_Antropometrica;
