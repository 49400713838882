import './frontend_v2.css';
import Header_v2 from './header_v2';
import { Row } from 'antd';
import { Container } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notificationController } from '@app/controllers/notificationController';
import { DatosAntropometricosModel } from '@app/models/DatosAntropometricosModel';
import { doGuardarDatosAntropometricos } from '@app/api/slices/pacienteSlice';
import { getIdPaciente, getUsuario, isLoggin, isMedico, isPaciente } from '@app/utils/localStorage.service';
import { Loading } from '@app/components/common/Loading';
import { UserModel } from '@app/models/UserModel';
const userM: UserModel = {
  user_id: 0,
  id_usuario: 0,
  username: '',
  correo: '',
  email: {
    name: '',
    verified: false,
  },
  phone: {
    number: '',
    verified: false,
  },
  name: '',
  first_name: '',
  cat_type_user_id: 0,
  cat_type_user: {
    cat_type_user_id: 0,
    name: '',
  },
  cat_tipo_usuario: {
    id_cat_tipo_usuario: 0,
    nombre: '',
  },
  paciente: {
    id_paciente: 0,
    nombre: '',
    apellido_paterno: '',
    apellido_materno: '',
  },
  medico: {
    id_medico: 0,
    nombre: '',
    apellido_paterno: '',
    apellido_materno: '',
  },
  datos_contacto_usuario: {
    id_usuario: 0,
    celular: '',
    colonia: '',
    calle: '',
    num_interior: '',
    num_exterior: '',
    codigo_postal: '',
  },
};
const PerfilUsuario: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingPage, setLoadingPage] = useState(false);
  const [usuario, setUsuario] = useState(userM);
  console.log(getUsuario());
  useEffect(() => {
    if (isLoggin()) {
      console.log('con session');
    } else {
      notificationController.error({ message: 'Es necesario iniciar sesion.' });
      navigate('/');
    }
    setUsuario(getUsuario());
  }, []);

  if (isLoadingPage) {
    return <Loading />;
  }

  return (
    <div>
      <Header_v2 />
      <Container>
        <div className="green-bar">
          <Row>
            <h2 className="section-title">Perfil usuario</h2>
          </Row>
        </div>
        <br></br>
        <form>
          {isMedico() ? (
            <div className="row mb-2">
              <div className="col">
                <div data-mdb-input-init className="form-outline">
                  <label className="form-label" htmlFor="form6Example1">
                    Nombre:
                  </label>
                  <input
                    type="text"
                    id="form6Example1"
                    className="form-control"
                    value={usuario.medico.apellido_materno}
                  />
                </div>
              </div>
              <div className="col">
                <div data-mdb-input-init className="form-outline">
                  <label className="form-label" htmlFor="form6Example2">
                    Apellido Paterno
                  </label>
                  <input
                    type="text"
                    id="form6Example2"
                    className="form-control"
                    value={usuario.medico.apellido_paterno}
                  />
                </div>
              </div>
              <div className="col">
                <div data-mdb-input-init className="form-outline">
                  <label className="form-label" htmlFor="form6Example2">
                    Apellido Materno
                  </label>
                  <input
                    type="text"
                    id="form6Example2"
                    className="form-control"
                    value={usuario.medico.apellido_materno}
                  />
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {isPaciente() ? (
            <div className="row mb-2">
              <div className="col">
                <div data-mdb-input-init className="form-outline">
                  <label className="form-label" htmlFor="form6Example1">
                    Nombre:
                  </label>
                  <input type="text" id="form6Example1" className="form-control" value={usuario.paciente.nombre} />
                </div>
              </div>
              <div className="col">
                <div data-mdb-input-init className="form-outline">
                  <label className="form-label" htmlFor="form6Example2">
                    Apellido Paterno
                  </label>
                  <input
                    type="text"
                    id="form6Example2"
                    className="form-control"
                    value={usuario.paciente.apellido_paterno}
                  />
                </div>
              </div>
              <div className="col">
                <div data-mdb-input-init className="form-outline">
                  <label className="form-label" htmlFor="form6Example2">
                    Apellido Materno
                  </label>
                  <input
                    type="text"
                    id="form6Example2"
                    className="form-control"
                    value={usuario.paciente.apellido_materno}
                  />
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          <div data-mdb-input-init className="form-outline mb-4">
            <label className="form-label" htmlFor="form6Example3">
              Correo
            </label>
            <input type="text" id="form6Example3" className="form-control" value={usuario.correo} />
          </div>
          <div data-mdb-input-init className="form-outline mb-4">
            <label className="form-label" htmlFor="form6Example3">
              Dirección
            </label>
            <input
              type="text"
              id="form6Example3"
              className="form-control"
              value={usuario.datos_contacto_usuario.calle + ' ' + usuario.datos_contacto_usuario.colonia}
            />
          </div>
          <div className="form-buttons">
            <button type="button" onClick={() => navigate('/')}>
              Volver
            </button>
            <button type="submit" disabled={isLoading}>
              Guardar
            </button>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default PerfilUsuario;
