import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './DatosContactoForm.styles';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { Loading } from '@app/components/common/Loading';
import { doActualizarDatosContacto, doGetUsuarioByID } from '@app/api/slices/UsuarioSlice';
import { doGetCatalogoEsatados, doGetCatalogoMunicipios } from '@app/api/slices/catalogoSlice';

let initValues = {
  id_usuario: 0,
  tipo_usuario: '',
  cat_tipo_usuario: {
    nombre: '',
  },
};

interface CatEstado {
  id_entidad: number;
  entidad: string;
}

interface CatMunicipio {
  id: number;
  municipio: string;
}

const catE: CatEstado[] = [];
const catM: CatMunicipio[] = [];

export const DatosContactoForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingPage, setLoadingPage] = useState(true);

  const { t } = useTranslation();
  const { id_usuario } = useParams();
  const { tipo_usuario } = useParams();
  const [catEstados, setCatEatados] = useState(catE);
  const [catMunicipios, setCatMunicipios] = useState(catM);

  console.log(id_usuario);
  console.log(tipo_usuario);

  useEffect(() => {
    dispatch(doGetCatalogoEsatados())
      .unwrap()
      .then((res) => {
        setCatEatados(res.data);
        console.log(catEstados);
        dispatch(doGetUsuarioByID(id_usuario))
          .unwrap()
          .then((res) => {
            initValues = res.data;
            console.log(initValues);
            setLoadingPage(false);
          })
          .catch((err) => {
            notificationController.error({ message: err.message });
            setLoading(false);
          });
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  }, []);

  const handleSubmit = (values: any) => {
    values.id_usuario = initValues.id_usuario;
    values.tipo_usuario = initValues.cat_tipo_usuario.nombre;
    setLoading(true);
    dispatch(doActualizarDatosContacto(values))
      .unwrap()
      .then((res) => {
        notificationController.success({
          message: t('Tu cuenta se ha creado con éxito'),
          description: t('Ya puedes iniciar sesión'),
        });
        navigate('/auth/login');
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  const handleChange = (id_estado: any) => {
    dispatch(doGetCatalogoMunicipios(id_estado))
      .unwrap()
      .then((res) => {
        setCatMunicipios(res.data);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  if (isLoadingPage) {
    return <Loading />;
  }

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <S.Title>{t('Datos de contacto')}</S.Title>
        <Auth.FormItem
          name="celular"
          label={t('Teléfono celular')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('Teléfono celular')} />
        </Auth.FormItem>

        <BaseForm.Item
          name="id_estado"
          label={t('Estado')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Select placeholder={t('Estado')} onChange={handleChange}>
            {catEstados.map((item) => (
              <Option key={item.id_entidad} value={item.id_entidad}>
                {item.entidad}
              </Option>
            ))}
          </Select>
        </BaseForm.Item>

        <BaseForm.Item
          name="id_municipio"
          label={t('Municipio')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Select placeholder={t('Municipio')}>
            {catMunicipios.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.municipio}
              </Option>
            ))}
          </Select>
        </BaseForm.Item>

        <Auth.FormItem
          name="colonia"
          label={t('Fraccionamiento o colonia')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('Fraccionamiento o colonia')} />
        </Auth.FormItem>

        <Auth.FormItem name="calle" label={t('Calle')} rules={[{ required: true, message: t('common.requiredField') }]}>
          <Auth.FormInput placeholder={t('Calle')} />
        </Auth.FormItem>

        <Auth.FormItem
          name="num_interior"
          label={t('Número interior')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('Número interior')} />
        </Auth.FormItem>

        <Auth.FormItem
          name="num_exterior"
          label={t('Número exterior')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('Número exterior')} />
        </Auth.FormItem>

        <Auth.FormItem
          name="codigo_postal"
          label={t('Código postal')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput placeholder={t('Código postal')} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('Guardar')}
          </Auth.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
