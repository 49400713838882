import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
const IndexLayout = React.lazy(() => import('@app/components/layouts/index/IndexLayout'));
import LoginPage from '@app/pages/auth/LoginPage';
import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import { withLoading } from '@app/hocs/withLoading.hoc';
import HomePage from '@app/pages/home/HomePage';
import RequireAuth from './RequireAuth';
import RegistroPage from './pages/auth/RegistroPage';
import ConfirmarRegistroPage from './pages/auth/ConfirmarRegistroPage';
import DatosUsuarioPage from './pages/auth/DatosUsuarioPage';
import DatosContactoPage from './pages/auth/DatosContactoPage';
import Pag_Inicio_v2 from './components/pages/frontend_v2/Inicio_v2';
import Acerca_de_v2 from './components/pages/frontend_v2/acercade_v2';
import Menu_Analisis_Datos_v2 from './components/pages/frontend_v2/menu_analisis_datos_v2';
import Captura_Antropometrica from './components/pages/frontend_v2/captura_antropometrica';
import Captura_Act_Fisica from './components/pages/frontend_v2/captura_act_fisica';
import ConsultaDatosQuimicos from './components/pages/frontend_v2/consulta_datos_quimicos';
import Genetica_Acerca_de_v2 from './components/pages/frontend_v2/consulta_datos_quimicos';
import Resultados_geneticos from './components/pages/frontend_v2/resultados_geneticos';
import Resultados_bioquimicos from './components/pages/frontend_v2/resultados_bioquimicos';
import Nivel_Riesgo from './components/pages/frontend_v2/nivel_riesgo';
import Capturar_Datos from './components/pages/inr_captura/capturar_datos';
import Antro_measures from './components/pages/inr_captura/antropometric_measures';
import Biochemical_Measures from './components/pages/inr_captura/biochemical_measures';
import Genetic_data from './components/pages/inr_captura/genetic_data';
import Sleep_cycle from './components/pages/inr_captura/sleep_cycle';
import CapturaSME from './components/pages/frontend_v2/captura_sme';
import CapturaCalculadora from './components/pages/frontend_v2/captura_calculadora';
import ConsultaActividadFisica from './components/pages/frontend_v2/consulta_actividad_fisica';
import ConsultaDatosAntropometricos from './components/pages/frontend_v2/consulta_datos_antropometricos';
import CapturaDatosBioquimicos from './components/pages/frontend_v2/captura_datos_bioquimicos';
import CapturaDatosGeneticos from './components/pages/frontend_v2/captura_datos_geneticos';
import ConsultaDatosQuimicosDetalle from './components/pages/frontend_v2/consulta_datos_quimicos_detalle';
import ConsultaDatosGeneticos from './components/pages/frontend_v2/consulta_datos_geneticos';
import ConsultaDatosGeneticosDetalle from './components/pages/frontend_v2/consulta_datos_geneticos_detalle';
import PerfilUsuario from './components/pages/frontend_v2/perfil_usuario';

const Logout = React.lazy(() => import('./Logout'));
export const DASH = '/dashboard';

const HomePaged = withLoading(HomePage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);
const IndexLayoutFallback = withLoading(IndexLayout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASH} element={protectedLayout}>
          <Route element={<HomePaged />} />
        </Route>
        <Route path="/sme" element={<CapturaSME />} /> {/* Nueva ruta para SMEPage */}
        <Route path="/calculadora" element={<CapturaCalculadora />} />
        <Route path="/consulta_actividad_fisica" element={<ConsultaActividadFisica />} />
        <Route path="/capturar_datos" element={<Capturar_Datos />} /> {/* Nueva ruta para menu de captura de datos */}
        <Route path="/mediciones_antropometricas" element={<Antro_measures />} />
        <Route path="/consulta_datos_antropometricos" element={<ConsultaDatosAntropometricos />} />
        <Route path="/mediciones_bioquimicas" element={<Biochemical_Measures />} />
        <Route path="/captura_datos_bioquimicos" element={<CapturaDatosBioquimicos />} />
        <Route path="/ciclo_sueno" element={<Sleep_cycle />} />
        <Route path="/datos_geneticos" element={<Genetic_data />} />
        <Route index element={<Pag_Inicio_v2 />} />
        <Route path="/acerca_de_v2" element={<Acerca_de_v2 />} />
        <Route path="/analisis_datos_v2" element={<Menu_Analisis_Datos_v2 />} />
        <Route path="/genetica_acerca_de" element={<Genetica_Acerca_de_v2 />} />
        <Route path="/consulta_datos_bioquimicos" element={<ConsultaDatosQuimicos />} />
        <Route path="/consulta_datos_bioquimicos_detalle/:id" element={<ConsultaDatosQuimicosDetalle />} />
        <Route path="/consulta_datos_geneticos" element={<ConsultaDatosGeneticos />} />
        <Route path="/consulta_datos_geneticos_detalle/:id" element={<ConsultaDatosGeneticosDetalle />} />
        <Route path="/resultados_geneticos" element={<Resultados_geneticos />} />
        <Route path="/resultados_bioquimicos" element={<Resultados_bioquimicos />} />
        <Route path="/captura_antropometrica" element={<Captura_Antropometrica />} />
        <Route path="/captura_act_fisica" element={<Captura_Act_Fisica />} />
        <Route path="/nivel_riesgo" element={<Nivel_Riesgo />} />
        <Route path="/captura_datos_geneticos" element={<CapturaDatosGeneticos />} />
        <Route path="/profile" element={<PerfilUsuario />} />
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="registro" element={<RegistroPage />} />
          <Route path="confirmar-registro" element={<ConfirmarRegistroPage />} />
          <Route path="datos-usuario/:code" element={<DatosUsuarioPage />} />
          <Route path="datos-contacto/:id_usuario/:tipo_usuario" element={<DatosContactoPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
        <Route path="/" element={<IndexLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
