/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Header_v2 from './header_v2';
import { useState } from 'react';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notificationController } from '@app/controllers/notificationController';
import { doConsultarNivelRiesgo } from '@app/api/slices/pacienteSlice';
import { getIdPaciente, isLoggin, isPaciente } from '@app/utils/localStorage.service';
import { Loading } from '@app/components/common/Loading';

const Nivel_Riesgo = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoadingPage, setLoadingPage] = useState(true);
  const { t } = useTranslation();
  const [riesgoAlto, setRiesgo] = useState(false);

  useEffect(() => {
    if (isLoggin() != null) {
      if (isPaciente()) {
        dispatch(doConsultarNivelRiesgo(getIdPaciente()))
          .unwrap()
          .then((res: any) => {
            setRiesgo(res.data.alto_riesgo);
            setLoadingPage(false);
          })
          .catch((err) => {
            notificationController.error({ message: err.message });
          });
      } else {
        notificationController.error({ message: 'Solo los pacientes tienen acceso' });
        navigate('/');
      }
    } else {
      notificationController.error({ message: 'Es necesario iniciar sesion.' });
      navigate('/');
    }
  }, []);

  if (isLoadingPage) {
    return <Loading />;
  }

  return (
    <div>
      <Header_v2 />
      <div className="NivelRiesgo">
        <div className="green-bar">
          <h2 className="h2-nav">Nivel de riesgo</h2>
        </div>

        {!riesgoAlto ? (
          <div className="riesgo-container">
            <div className="color-riesgo"></div>
            <h2>Sin riesgo detectado.</h2>
          </div>
        ) : (
          <div className="riesgo-container">
            <div className="color-riesgo-alto"></div>
            <h2>Alto riesgo.</h2>
          </div>
        )}

        <div className="purple-bar-bottom" />
      </div>
    </div>
  );
};

export default Nivel_Riesgo;
