const Capturar_Datos = () => {
  return (
    <div className="capturar-datos__buttons">
      <a href="mediciones_antropometricas"> Mediciones antropométricas </a>
      <a href="/mediciones_bioquimicas"> Pruebas bioquímicas </a>
      <a href="/datos_geneticos"> Datos genéticos </a>
      <a href="/ciclo_sueno"> Ciclo de sueño </a>
      <a href="/"> Volver </a>
    </div>
  );
};

export default Capturar_Datos;
