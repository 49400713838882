import { httpApi } from '@app/api/http.api';

export const getPacienteById = (paciente: number): Promise<any> =>
  httpApi.get<any>(`/paciente/getPacienteById/${paciente}`).then(({ data }) => data);

export const guardarDatosAntropometricos = (data: any): Promise<any> =>
  httpApi.post<any>(`/paciente/guardarDatosAntropometricos`, { ...data }).then(({ data }) => data);

export const guardarActividadFisica = (data: any): Promise<any> =>
  httpApi.post<any>(`/paciente/guardarActividadFisica`, { ...data }).then(({ data }) => data);

export const guardarSME = (data: any): Promise<any> =>
  httpApi.post<any>(`/paciente/guardarSME`, { ...data }).then(({ data }) => data);

export const consultarNivelRiesgo = (id_paciente: number): Promise<any> =>
  httpApi.get<any>(`/paciente/consultarNivelRiesgo/${id_paciente}`).then(({ data }) => data);

export const consultarActividadFisica = (id_paciente: number): Promise<any> =>
  httpApi.get<any>(`/paciente/consultarActividadFisica/${id_paciente}`).then(({ data }) => data);

export const consultarDatosAntropometricos = (id_paciente: number): Promise<any> =>
  httpApi.get<any>(`/paciente/consultarDatosAntropometricos/${id_paciente}`).then(({ data }) => data);

export const consultarListaPacientes = (): Promise<any> =>
  httpApi.get<any>(`/paciente/consultarListaPacientes`).then(({ data }) => data);

export const guardarDatosQuimicos = (data: any): Promise<any> =>
  httpApi.post<any>(`/paciente/guardarDatosQuimicos`, { ...data }).then(({ data }) => data);

export const consultarDatosQuimicos = (id_paciente: string, id_medico: string): Promise<any> =>
  httpApi.get<any>(`/paciente/consultarDatosQuimicos/${id_paciente}/${id_medico}`).then(({ data }) => data);

export const consultarDatosQuimicosDetalle = (id_medicion_quimico: string): Promise<any> =>
  httpApi.get<any>(`/paciente/consultarDatosQuimicosDetalle/${id_medicion_quimico}`).then(({ data }) => data);

export const guardarDatosGeneticos = (data: any): Promise<any> =>
  httpApi.post<any>(`/paciente/guardarDatosGeneticos`, { ...data }).then(({ data }) => data);

export const consultarDatosGeneticos = (id_paciente: string, id_medico: string): Promise<any> =>
  httpApi.get<any>(`/paciente/consultarDatosGeneticos/${id_paciente}/${id_medico}`).then(({ data }) => data);

export const consultarDatosGeneticosDetalle = (id_medicion_gen: string): Promise<any> =>
  httpApi.get<any>(`/paciente/consultarDatosGeneticosDetalle/${id_medicion_gen}`).then(({ data }) => data);
