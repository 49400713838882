import { UserModel } from '@app/models/UserModel';

export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};

export const readToken = (): string | null => {
  return localStorage.getItem('accessToken');
};

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');
  return userStr ? JSON.parse(userStr) : null;
};

export const getUsuario = (): UserModel => {
  const userStr = localStorage.getItem('user');
  return userStr ? JSON.parse(userStr) : null;
};

export const isLoggin = (): boolean => {
  const userStr = localStorage.getItem('user');
  return userStr != null ? true : false;
};

export const isPaciente = (): boolean => {
  const user = readUser();
  return user?.cat_tipo_usuario.nombre == 'PACIENTE' ? true : false;
};

export const isMedico = (): boolean => {
  const user = readUser();
  return user?.cat_tipo_usuario.nombre == 'MEDICO' ? true : false;
};

export const getIdPaciente = (): number => {
  return getUsuario().paciente.id_paciente;
};

export const getIdMedico = (): number => {
  return getUsuario().medico.id_medico;
};

export const getIdUsuario = (): number => {
  return getUsuario().id_usuario;
};

export const deleteToken = (): void => localStorage.removeItem('accessToken');
export const deleteUser = (): void => localStorage.removeItem('user');
