import React from 'react';
import './resultados.css';
import { Link } from 'react-router-dom';

const Resultados_bioquimicos = () => {
  return (
    <div className="div-table">
      <table className="results-table">
        <td colSpan={4} className="table-title">
          Resultados Bioquimicos
        </td>

        <tbody>
          <td colSpan={2}>Paciente:</td>
          <td colSpan={2}>Jose Lopez</td>
          <tr>
            <td className="green-cell">Edad:</td>
            <td>38 AÑOS</td>
            <td className="green-cell">Fecha:</td>
            <td>18/04/2024</td>
          </tr>
          <tr>
            <td className="green-cell">Dirección:</td>
            <td>GUADALAJARA NORTE</td>
            <td className="green-cell">Identificación:</td>
            <td>4009768</td>
          </tr>
          <tr>
            <td className="green-cell">Toma de muestra:</td>
            <td>ZAPOPAN, 18/04/2024</td>
            <td className="green-cell">Género:</td>
            <td>MASCULINO</td>
          </tr>
          <tr>
            <td className="green-cell">Recepción de muestra:</td>
            <td colSpan={3}>18/04/2024 08:31</td>
          </tr>

          <td colSpan={4} className="table-title">
            Hematología
          </td>

          <tr className="no-border">
            <th>EXAMEN</th>
            <th>RESULTADO</th>
            <th>UNIDAD</th>
            <th>RANGO DE REFERENCIA</th>
          </tr>
          <tr>
            <td colSpan={4} className="green-text">
              BIOMETRÍA HEMÁTICA
            </td>
          </tr>
          <tr className="no-border">
            <td>LEUCOCITOS</td>
            <td>6</td>
            <td>K/ul</td>
            <td>[4.50 - 11.00]</td>
          </tr>
          <tr className="no-border">
            <td>NEUTRÓFILOS</td>
            <td>50.4</td>
            <td>%</td>
            <td>[50.00 - 70.00]</td>
          </tr>
          <tr className="no-border">
            <td>EOSINÓFILOS</td>
            <td>5.9</td>
            <td>%</td>
            <td>[1.00 - 4.00]</td>
          </tr>
          <tr className="no-border">
            <td>LINFOCITOS</td>
            <td>34.7</td>
            <td>%</td>
            <td>[18.00 - 42.00]</td>
          </tr>
          <tr className="no-border">
            <td>MONOCITOS</td>
            <td>8.1</td>
            <td>%</td>
            <td>[2.00 - 8.00]</td>
          </tr>
          <tr className="no-border">
            <td>BASÓFILOS</td>
            <td>0.5</td>
            <td>%</td>
            <td>[0.50 - 1.00]</td>
          </tr>
          <tr className="no-border">
            <td>NEUTRÓFILOS</td>
            <td>3.02</td>
            <td>UL</td>
            <td>[2.50 - 7.50]</td>
          </tr>
          <tr className="no-border">
            <td>EOSINÓFILOS</td>
            <td>0.35</td>
            <td>UL</td>
            <td>[0.05 - 0.50]</td>
          </tr>
          <tr className="no-border">
            <td>LINFOCITOS</td>
            <td>2.05</td>
            <td>UL</td>
            <td>[1.30 - 4.00]</td>
          </tr>
          <tr className="no-border">
            <td>MONOCITOS</td>
            <td>0.49</td>
            <td>UL</td>
            <td>[0.20 - 0.80]</td>
          </tr>
          <tr className="no-border">
            <td>BASÓFILOS</td>
            <td>0.05</td>
            <td>UL</td>
            <td>[0.00 - 0.10]</td>
          </tr>
          <tr className="no-border">
            <td>HEMATÍES</td>
            <td>5.6</td>
            <td>/Mut</td>
            <td>[4.50 - 5.90]</td>
          </tr>
          <tr className="no-border">
            <td>HEMOGLOBINA</td>
            <td>16.7</td>
            <td>g/dL</td>
            <td>[13.20 - 16.50]</td>
          </tr>
          <tr className="no-border">
            <td>HEMATOCRITO</td>
            <td>50.1</td>
            <td>%</td>
            <td>[42.00 - 52.00]</td>
          </tr>
          <tr className="no-border">
            <td>VCM</td>
            <td>88.1</td>
            <td>fl</td>
            <td>[80.00 - 100.00]</td>
          </tr>
          <tr className="no-border">
            <td>HCM</td>
            <td>30.1</td>
            <td>pg</td>
            <td>[28.00 - 32.80]</td>
          </tr>
          <tr className="no-border">
            <td>CHCM</td>
            <td>34.8</td>
            <td>g/dL</td>
            <td>[32.00 - 36.00]</td>
          </tr>
          <tr className="no-border">
            <td>RDW</td>
            <td>13.2</td>
            <td>%</td>
            <td>[11.50 - 14.50]</td>
          </tr>
          <tr className="no-border">
            <td>PLAQUETAS</td>
            <td>242</td>
            <td>K/ul</td>
            <td>[150.00 - 450.00]</td>
          </tr>
          <tr className="no-border">
            <td>VPM</td>
            <td>9.2</td>
            <td>%</td>
            <td>[7.40 - 10.40]</td>
          </tr>
          <tr className="no-border">
            <td>PCT</td>
            <td>0.32</td>
            <td>%</td>
            <td>[0.10 - 1.00]</td>
          </tr>
          <tr className="no-border">
            <td>IDP</td>
            <td>16</td>
            <td>%</td>
            <td>[11.00 - 18.00]</td>
          </tr>
          <tr>
            <td colSpan={4}>Validador por: VELOZ PAUCAR SONIA PATRICIA - 18/04/2024 11:06</td>
          </tr>

          <td colSpan={4} className="table-title">
            Química Sanguínea
          </td>

          <tr className="no-border">
            <th>EXAMEN</th>
            <th>RESULTADO</th>
            <th>UNIDAD</th>
            <th>RANGO DE REFERENCIA</th>
          </tr>
          <tr className="no-border">
            <td>GLUCOSA</td>
            <td>98</td>
            <td>mg/dL</td>
            <td>[70.00 - 100.00]</td>
          </tr>
          <tr className="no-border">
            <td>CREATININA</td>
            <td>1.04</td>
            <td>mg/dL</td>
            <td>[0.80 - 1.30]</td>
          </tr>
          <tr className="no-border">
            <td>ÁCIDO ÚRICO</td>
            <td>5.6</td>
            <td>mg/dL</td>
            <td>[3.50 - 7.20]</td>
          </tr>
          <tr className="no-border">
            <td>COLESTEROL</td>
            <td>231</td>
            <td>mg/dL</td>
            <td>CONVENIENTE [0.00 - 200.00] LÍMITE ELEVADO [200.00 - 239.00] ELEVADO 240.00</td>
          </tr>

          <tr className="no-border">
            <td>LDL Colesterol</td>
            <td>98</td>
            <td>mg/dL</td>
            <td>[70.00 - 100.00]</td>
          </tr>
          <tr className="no-border">
            <td>HDL Colesterol</td>
            <td>98</td>
            <td>mg/dL</td>
            <td>[70.00 - 100.00]</td>
          </tr>
          <tr className="no-border">
            <td>Trigliceridos</td>
            <td>1.04</td>
            <td>mg/dL</td>
            <td>[0.80 - 1.30]</td>
          </tr>
          <tr className="no-border">
            <td>AST (GOT)</td>
            <td>5.6</td>
            <td>mg/dL</td>
            <td>[3.50 - 7.20]</td>
          </tr>
          <tr className="no-border">
            <td>ALT (GPT)</td>
            <td>231</td>
            <td>mg/dL</td>
            <td>CONVENIENTE [0.00 - 200.00] LÍMITE ELEVADO [200.00 - 239.00] ELEVADO 240.00</td>
          </tr>
        </tbody>
      </table>
      <div className="form-buttons">
        <Link to="/bioquimica_acerca_de">
          <button type="button">Volver</button>
        </Link>
        <button type="button">Descargar resultados</button>
      </div>
    </div>
  );
};

export default Resultados_bioquimicos;
