import React, { useState } from 'react';
import { Container, Card, Button, Form } from 'react-bootstrap';

const Biochemical_Measures = () => {
  return (
    <div>
      <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
        <div style={{ maxWidth: '400px' }}>
          <Card className="d-flex align-items-center justify-content-center flex-column shadow-lg ">
            <Card.Body>
              <h2 className="text-center">Captura de datos</h2>
              <h6 className="text-center">Datos bioquímicos</h6>

              <hr />
              <Form>
                <Form.Group className="mb-2" id="glucosa">
                  <Form.Label>Glucosa en ayunas</Form.Label>
                  <Form.Control type="glucosa" placeholder="mg/dL..." required />
                </Form.Group>
                <Form.Group className="mb-2 " id="trigliceridos">
                  <Form.Label>Triglicéridos</Form.Label>
                  <Form.Control type="trigliceridos" placeholder="mg/dL..." required />
                </Form.Group>
                <Form.Group className="mb-2" id="presion">
                  <Form.Label>Presión arterial</Form.Label>
                  <Form.Control type="presion_arterial" placeholder="mmHg..." required />
                </Form.Group>
                <Form.Group id="colesterol">
                  <Form.Label>Colesterol</Form.Label>
                  <div className="d-flex justify-content-start">
                    <Form.Group className="mb-2" id="hdl">
                      <Form.Label>HDL</Form.Label>
                      <Form.Control type="HDL" placeholder="mg/dL..." required />
                    </Form.Group>
                    <Form.Group className="mb-2" id="vldl">
                      <Form.Label>VLDL</Form.Label>
                      <Form.Control type="VLDL" placeholder="mg/dL..." required />
                    </Form.Group>
                    <Form.Group className="mb-2" id="ldl">
                      <Form.Label>LDL</Form.Label>
                      <Form.Control type="LDL" placeholder="mg/dL..." required />
                    </Form.Group>
                  </div>
                </Form.Group>
                <hr />
                <div className="text-center">
                  <Button className="w-75 mt-3 text-center" type="submit">
                    Enviar datos
                  </Button>
                  <div className="text-center">
                    <a href="/capturar_datos"> Volver </a>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default Biochemical_Measures;
