import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col } from 'react-bootstrap';
import './acercade_v2.css';
import Adn_icon from '../../../assets/icons/analisis_de_datos_adn.png';
import Header_v2 from './header_v2';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getIdMedico, getIdPaciente, isLoggin, isPaciente, isMedico } from '@app/utils/localStorage.service';
import { AnalisisQuimicoModel } from '@app/models/AnalisisQuimicoModel';
import { doConsultarDatosGeneticos, doConsultarDatosQuimicos } from '@app/api/slices/pacienteSlice';
import { notificationController } from '@app/controllers/notificationController';
import { Loading } from '@app/components/common/Loading';
import { ParamsModel } from '@app/models/ParamsModel';
import { AnalisisGeneticoModel } from '@app/models/AnalisGeneticoModel';

const ConsultaDatosGeneticos = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoadingPage, setLoadingPage] = useState(true);
  const { t } = useTranslation();
  const analisisG: AnalisisGeneticoModel[] = [];
  const [analisisGeneticos, setAnalisisGeneticos] = useState(analisisG);
  useEffect(() => {
    if (isLoggin() != null) {
      const paramsData: ParamsModel = {
        id_paciente: '',
        id_medico: '',
      };
      if (isPaciente()) {
        paramsData.id_paciente = getIdPaciente().toString();
        paramsData.id_medico = '0';
      } else {
        if (isMedico()) {
          paramsData.id_paciente = '0';
          paramsData.id_medico = getIdMedico().toString();
        }
      }
      dispatch(doConsultarDatosGeneticos(paramsData))
        .unwrap()
        .then((res) => {
          console.log(res.data);
          setLoadingPage(false);
          setAnalisisGeneticos(res.data);
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
        });
    } else {
      notificationController.error({ message: 'Es necesario iniciar sesion.' });
      navigate('/');
    }
  }, []);

  const viewResultado = (item: AnalisisQuimicoModel) => {
    console.log(item);
  };

  if (isLoadingPage) {
    return <Loading />;
  }

  return (
    <div>
      <Header_v2 />
      <Container>
        <div className="green-bar">
          <Row>
            <h2 className="section-title">Consulta de datos genéticos</h2>
          </Row>
        </div>

        <Row>
          <Col>
            <div>
              <img className="acerca_de_figura" src={Adn_icon} />
            </div>
          </Col>
          <Col xs={7}>
            <div className="section-content">
              <b>
                La genética es crucial para comprender la predisposición individual a enfermedades y detectar el riesgo
                temprano. Nuestros análisis detallados identifican marcadores asociados con el síndrome metabólico,
                permitiendo no solo la detección temprana del riesgo, sino también revelando predisposiciones genéticas
                a enfermedades como diabetes tipo 2, obesidad y enfermedades cardiovasculares. Esto significa que
                podemos adaptar estrategias de prevención y tratamiento específicamente a tus necesidades individuales.
              </b>
            </div>
          </Col>
        </Row>
        <div>
          <h2>Resultados geneticos</h2>
          <table className="table">
            <thead className="text-center">
              <tr>
                <th scope="col">#</th>
                {isMedico() ? <th scope="col">Paciente</th> : ''}
                {isPaciente() ? <th scope="col">Medico</th> : ''}
                <th scope="col">Fecha</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {analisisGeneticos.map((item, index) => (
                <tr key={item.id_medicion_gen}>
                  <td>{index + 1}</td>
                  {isMedico() ? (
                    <td>
                      {item.paciente?.nombre} {item.paciente?.apellido_paterno} {item.paciente?.apellido_materno}
                    </td>
                  ) : (
                    ''
                  )}
                  {isPaciente() ? (
                    <td>
                      {item.medico?.nombre} {item.medico?.apellido_paterno} {item.medico?.apellido_materno}
                    </td>
                  ) : (
                    ''
                  )}
                  <td>{item.fecha}</td>
                  <td>
                    <button
                      type="button"
                      onClick={() => navigate('/consulta_datos_geneticos_detalle/' + item.id_medicion_gen)}
                    >
                      Ver resultados
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="form-buttons">
          <button type="button" onClick={() => navigate('/analisis_datos_v2')}>
            Volver
          </button>
        </div>
      </Container>
    </div>
  );
};

export default ConsultaDatosGeneticos;
