import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  consultarActividadFisica,
  consultarDatosAntropometricos,
  consultarDatosGeneticos,
  consultarDatosGeneticosDetalle,
  consultarDatosQuimicos,
  consultarDatosQuimicosDetalle,
  consultarListaPacientes,
  consultarNivelRiesgo,
  getPacienteById,
  guardarActividadFisica,
  guardarDatosAntropometricos,
  guardarDatosGeneticos,
  guardarDatosQuimicos,
  guardarSME,
} from '../paciente.api';
import { ParamsModel } from '@app/models/ParamsModel';

export const doGetPacienteById = createAsyncThunk('paciente/getPacienteById/', async (id_paciente: any) =>
  getPacienteById(id_paciente).then((res) => {
    return res;
  }),
);

export const doGuardarDatosAntropometricos = createAsyncThunk(
  'paciente/guardarDatosAntropometricos',
  async (data: any) =>
    guardarDatosAntropometricos(data).then((res) => {
      return res;
    }),
);

export const doConsultarDatosAntropometricos = createAsyncThunk(
  'paciente/consultarDatosAntropometricos',
  async (id_paciente: any) =>
    consultarDatosAntropometricos(id_paciente).then((res) => {
      return res;
    }),
);

export const doGuardarActividadFisica = createAsyncThunk('paciente/guardarActividadFisica', async (data: any) =>
  guardarActividadFisica(data).then((res) => {
    return res;
  }),
);

export const doConsultarActividadFisica = createAsyncThunk(
  'paciente/consultarActividadFisica',
  async (id_paciente: any) =>
    consultarActividadFisica(id_paciente).then((res) => {
      return res;
    }),
);

export const doGuardarSME = createAsyncThunk('paciente/guardarSME', async (data: any) =>
  guardarSME(data).then((res) => {
    return res;
  }),
);

export const doConsultarNivelRiesgo = createAsyncThunk('paciente/consultarNivelRiesgo', async (id_paciente: any) =>
  consultarNivelRiesgo(id_paciente).then((res) => {
    return res;
  }),
);

export const doConsultarListaPacientes = createAsyncThunk('paciente/consultarListaPacientes', async () =>
  consultarListaPacientes().then((res) => {
    return res;
  }),
);

export const doGuadarDatosQuimicos = createAsyncThunk('paciente/guardarDatosQuimicos', async (data: any) =>
  guardarDatosQuimicos(data).then((res) => {
    return res;
  }),
);

export const doConsultarDatosQuimicos = createAsyncThunk('paciente/consultarDatosQuimicos', async (data: ParamsModel) =>
  consultarDatosQuimicos(data.id_paciente, data.id_medico).then((res) => {
    return res;
  }),
);

export const doConsultarDatosQuimicosDetalle = createAsyncThunk(
  'paciente/consultarDatosQuimicosDetalle',
  async (id_medicion_quimico: any) =>
    consultarDatosQuimicosDetalle(id_medicion_quimico).then((res) => {
      return res;
    }),
);

export const doGuadarDatosGeneticos = createAsyncThunk('paciente/guardarDatosGeneticos', async (data: any) =>
  guardarDatosGeneticos(data).then((res) => {
    return res;
  }),
);

export const doConsultarDatosGeneticos = createAsyncThunk(
  'paciente/consultarDatosGeneticos',
  async (data: ParamsModel) =>
    consultarDatosGeneticos(data.id_paciente, data.id_medico).then((res) => {
      return res;
    }),
);

export const doConsultarDatosGeneticosDetalle = createAsyncThunk(
  'paciente/consultarDatosGeneticosDetalle',
  async (id_medicion_quimico: any) =>
    consultarDatosGeneticosDetalle(id_medicion_quimico).then((res) => {
      return res;
    }),
);
const pacienteSlice = createSlice({
  name: 'paciente',
  reducers: {},
  initialState: undefined,
});

export default pacienteSlice.reducer;
