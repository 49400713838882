import React from 'react';
import './resultados.css';
import { Link } from 'react-router-dom';

const Resultados_geneticos = () => {
  return (
    <div className="div-table">
      <table className="results-table">
        <td colSpan={2} className="table-title">
          Resultados de pruebas de análisis genético
        </td>
        <tbody>
          <tr>
            <td>Nombre del paciente</td>
            <td>Juan Lopez</td>
          </tr>
          <tr>
            <td>Fecha de la prueba</td>
            <td>26/Abril/2024</td>
          </tr>
          <tr>
            <td>Tipo de prueba</td>
            <td>Sangre/Saliva</td>
          </tr>
          <tr className="no-border">
            <td>Variante genética de riesgo</td>
            <td>Se detectó (Sí/No)</td>
          </tr>
          <tr className="no-border">
            <td>Polimorfismo 1</td>
            <td>No</td>
          </tr>
          <tr className="no-border">
            <td>Polimorfismo 2</td>
            <td>No</td>
          </tr>
          <tr className="no-border">
            <td>Polimorfismo 3</td>
            <td>No</td>
          </tr>
          <tr className="no-border">
            <td>Polimorfismo 4</td>
            <td>Sí</td>
          </tr>
          <tr className="no-border">
            <td>Polimorfismo 5</td>
            <td>Sí</td>
          </tr>
          <tr className="no-border">
            <td>Polimorfismo 6</td>
            <td>No</td>
          </tr>
          <tr className="no-border">
            <td>Polimorfismo 7</td>
            <td>No</td>
          </tr>
          <tr className="no-border">
            <td>Polimorfismo 8</td>
            <td>Sí</td>
          </tr>
          <tr className="no-border">
            <td>Polimorfismo 9</td>
            <td>Sí</td>
          </tr>
          <tr className="no-border">
            <td>Polimorfismo 10</td>
            <td>Sí</td>
          </tr>
          <tr className="no-border">
            <td>Polimorfismo 11</td>
            <td>No</td>
          </tr>
          <tr className="no-border">
            <td>Polimorfismo 12</td>
            <td>Sí</td>
          </tr>
          <tr className="no-border">
            <td>Polimorfismo 13</td>
            <td>No</td>
          </tr>
          <tr>
            <td colSpan={2} className="table-title">
              Resumen de resultados
            </td>
          </tr>
          <tr>
            <td>Nivel de riesgo de síndrome metabólico</td>
            <td>[Bajo/Moderado/Alto]</td>
          </tr>
          <tr>
            <td>Comentarios</td>
            <td className="comment-cell">[Breve explicación sobre el riesgo genético]</td>
          </tr>
          <tr>
            <td colSpan={2} className="table-title">
              Predisposición a condiciones relacionadas
            </td>
          </tr>
          <tr>
            <td>Diabetes tipo 2</td>
            <td>[Bajo/Moderado/Alto]</td>
          </tr>
          <tr>
            <td>Obesidad</td>
            <td>[Bajo/Moderado/Alto]</td>
          </tr>
          <tr>
            <td>Enfermedades cardiovasculares</td>
            <td>[Bajo/Moderado/Alto]</td>
          </tr>
          <tr>
            <td>Comentarios</td>
            <td>[Breve explicación sobre el riesgo genético]</td>
          </tr>
        </tbody>
      </table>
      <div className="form-buttons">
        <Link to="/genetica_acerca_de">
          <button type="button">Volver</button>
        </Link>
        <button type="button">Descargar resultados</button>
      </div>
    </div>
  );
};

export default Resultados_geneticos;
