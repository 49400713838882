import './frontend_v2.css';
import React, { useEffect, useState } from 'react';
import './frontend_v2.css';
import Header_v2 from './header_v2';
import { Row } from 'antd';
import { Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { notificationController } from '@app/controllers/notificationController';
import { Loading } from '@app/components/common/Loading';
import { getIdMedico, isLoggin, isMedico } from '@app/utils/localStorage.service';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { PacienteModel } from '@app/models/PacienteModel';
import { doConsultarListaPacientes, doGuadarDatosGeneticos } from '@app/api/slices/pacienteSlice';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { AnalisisGeneticoModel } from '@app/models/AnalisGeneticoModel';

const pacientesModel: PacienteModel[] = [];
const formValues: AnalisisGeneticoModel = {
  id_medicion_gen: '',
  id_paciente: '',
  id_medico: '',
  variante_genetica_riesgo: '',
  tipo_prueba: '',
  gen1: '',
  gen2: '',
  gen3: '',
  gen4: '',
  gen5: '',
  gen6: '',
  gen7: '',
  gen8: '',
  gen9: '',
  gen10: '',
  gen11: '',
  fecha: '',
  comentarios_resultados: '',
  comentarios_condicion: '',
  paciente: null,
  medico: null,
};

const CapturaDatosGeneticos: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoadingPage, setLoadingPage] = useState(true);
  const { t } = useTranslation();
  const [listPacientes, setListPacientes] = useState(pacientesModel);

  useEffect(() => {
    if (isLoggin() != null) {
      if (isMedico()) {
        dispatch(doConsultarListaPacientes())
          .unwrap()
          .then((res: any) => {
            console.log(res.data);
            setListPacientes(res.data);
            setLoadingPage(false);
          })
          .catch((err) => {
            notificationController.error({ message: err.message });
            setLoadingPage(false);
          });
      } else {
        notificationController.error({ message: 'Solo los medicos tienen acceso' });
        navigate('/');
      }
    } else {
      notificationController.error({ message: 'Es necesario iniciar sesion.' });
      navigate('/');
    }
  }, []);

  const guardarDatosGeneticos = (values: AnalisisGeneticoModel) => {
    values.id_medico = getIdMedico().toString();
    setLoadingPage(true);
    dispatch(doGuadarDatosGeneticos(values))
      .unwrap()
      .then((res) => {
        notificationController.success({
          message: t('Operación exitosa.'),
          description: t('Datos geneticos guardados correctamente.'),
        });
        navigate('/analisis_datos_v2');
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoadingPage(false);
      });
  };

  if (isLoadingPage) {
    return <Loading />;
  }
  return (
    <div>
      <Header_v2 />
      <Container>
        <div className="green-bar">
          <Row>
            <h2 className="section-title">Captura datos geneticos</h2>
          </Row>
        </div>

        <br></br>
        <BaseForm layout="vertical" onFinish={guardarDatosGeneticos} requiredMark="optional" initialValues={formValues}>
          <div className="div-table">
            <table className="results-table">
              <td colSpan={2} className="table-title">
                Resultados de pruebas de análisis genético
              </td>
              <tbody>
                <tr>
                  <td>Nombre del paciente</td>
                  <td>
                    <BaseForm.Item name="id_paciente" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <Select width={600} placeholder={t('Selecciona el paciente')}>
                        {listPacientes.map((item) => (
                          <Option key={item.id_paciente} value={item.id_paciente}>
                            {item.nombre} {item.apellido_paterno} {item.apellido_materno}
                          </Option>
                        ))}
                      </Select>
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr>
                  <td>Fecha de la prueba</td>
                  <td>
                    <BaseForm.Item name="fecha" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="date" id="fecha" />
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr>
                  <td>Tipo de prueba</td>
                  <td>
                    <BaseForm.Item name="tipo_prueba" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="text" placeholder="Sangre/Saliva" id="tipo_prueba" />
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr className="no-border">
                  <td>Variante genética de riesgo</td>
                  <td>
                    <BaseForm.Item name="variante_genetica_riesgo">
                      <input type="Checkbox" id="variante_genetica_riesgo" />
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr className="no-border">
                  <td>Polimorfismo 1</td>
                  <td>
                    <BaseForm.Item name="gen1">
                      <input type="Checkbox" id="gen1" />
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr className="no-border">
                  <td>Polimorfismo 2</td>
                  <td>
                    <BaseForm.Item name="gen2">
                      <input type="Checkbox" id="gen2" />
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr className="no-border">
                  <td>Polimorfismo 3</td>
                  <td>
                    <BaseForm.Item name="gen3">
                      <input type="Checkbox" id="gen3" />
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr className="no-border">
                  <td>Polimorfismo 4</td>
                  <td>
                    <BaseForm.Item name="gen4">
                      <input type="Checkbox" id="gen4" />
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr className="no-border">
                  <td>Polimorfismo 5</td>
                  <td>
                    <BaseForm.Item name="gen5">
                      <input type="Checkbox" id="gen5" />
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr className="no-border">
                  <td>Polimorfismo 6</td>
                  <td>
                    <BaseForm.Item name="gen6">
                      <input type="Checkbox" id="gen6" />
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr className="no-border">
                  <td>Polimorfismo 7</td>
                  <td>
                    <BaseForm.Item name="gen7">
                      <input type="Checkbox" id="gen7" />
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr className="no-border">
                  <td>Polimorfismo 8</td>
                  <td>
                    <BaseForm.Item name="gen8">
                      <input type="Checkbox" id="gen8" />
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr className="no-border">
                  <td>Polimorfismo 9</td>
                  <td>
                    <BaseForm.Item name="gen9">
                      <input type="Checkbox" id="gen9" />
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr className="no-border">
                  <td>Polimorfismo 10</td>
                  <td>
                    <BaseForm.Item name="gen10">
                      <input type="Checkbox" id="gen10" />
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr className="no-border">
                  <td>Polimorfismo 11</td>
                  <td>
                    <BaseForm.Item name="gen11">
                      <input type="Checkbox" id="gen11" />
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="table-title">
                    Resumen de resultados
                  </td>
                </tr>
                <tr>
                  <td>Nivel de riesgo de síndrome metabólico</td>
                  <td>[Bajo/Moderado/Alto]</td>
                </tr>
                <tr>
                  <td>Comentarios</td>
                  <td>
                    <BaseForm.Item
                      name="comentarios_resultados"
                      rules={[{ required: true, message: t('common.requiredField') }]}
                    >
                      <input type="Text" placeholder="Explicación sobre el riesgo" id="comentarios_resultados" />
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="table-title">
                    Predisposición a condiciones relacionadas
                  </td>
                </tr>
                <tr>
                  <td>Diabetes tipo 2</td>
                  <td>[Bajo/Moderado/Alto]</td>
                </tr>
                <tr>
                  <td>Obesidad</td>
                  <td>[Bajo/Moderado/Alto]</td>
                </tr>
                <tr>
                  <td>Enfermedades cardiovasculares</td>
                  <td>[Bajo/Moderado/Alto]</td>
                </tr>
                <tr>
                  <td>Comentarios</td>
                  <td>
                    <BaseForm.Item
                      name="comentarios_condiciones"
                      rules={[{ required: true, message: t('common.requiredField') }]}
                    >
                      <input type="Text" placeholder="Explicación sobre el riesgo" id="comentarios_condiciones" />
                    </BaseForm.Item>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="form-buttons">
              <Link to="/analisis_datos_v2">
                <button type="button">Volver</button>
              </Link>
              <button type="submit">Guardar</button>
            </div>
          </div>
        </BaseForm>
      </Container>
    </div>
  );
};

export default CapturaDatosGeneticos;
