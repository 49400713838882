/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Header_v2 from './header_v2';
import { useState } from 'react';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notificationController } from '@app/controllers/notificationController';
import { doConsultarActividadFisica } from '@app/api/slices/pacienteSlice';
import { getIdPaciente, getUsuario, isLoggin, isPaciente } from '@app/utils/localStorage.service';
import { Loading } from '@app/components/common/Loading';
import { Container } from 'react-bootstrap';
import { Row } from 'antd';
import { ActividadFisicaModel } from '@app/models/ActividadFisicaDatosModel';

const ConsultaActividadFisica: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoadingPage, setLoadingPage] = useState(true);
  const { t } = useTranslation();
  const [paciente, setPaciente] = useState(getUsuario());
  const actividadF: ActividadFisicaModel[] = [];
  const [actividadFisica, setActividadFisica] = useState(actividadF);

  useEffect(() => {
    if (isLoggin() != null) {
      if (isPaciente()) {
        dispatch(doConsultarActividadFisica(getIdPaciente()))
          .unwrap()
          .then((res) => {
            setLoadingPage(false);
            setActividadFisica(res.data);
          })
          .catch((err) => {
            notificationController.error({ message: err.message });
          });
      } else {
        notificationController.error({ message: 'Solo los pacientes tienen acceso' });
        navigate('/');
      }
    } else {
      notificationController.error({ message: 'Es necesario iniciar sesion.' });
      navigate('/');
    }
  }, []);

  if (isLoadingPage) {
    return <Loading />;
  }

  return (
    <div>
      <Header_v2 />
      <Container>
        <div className="green-bar">
          <Row>
            <h2 className="section-title">Consulta actividad fisica</h2>
          </Row>
        </div>
        <br />
        <h4>
          Paciente: {paciente.paciente.nombre} {paciente.paciente.apellido_paterno} {paciente.paciente.apellido_materno}
        </h4>
        <div>
          <table className="table">
            <thead className="text-center">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Actividad fisica ligera</th>
                <th scope="col">Actividad fisica moderada</th>
                <th scope="col">Actividad fisica intensa</th>
                <th scope="col">Gasto energetico basal</th>
                <th scope="col">Gasto energetico total</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {actividadFisica.map((item) => (
                <tr key={item.id_actividad_fisica}>
                  <td>{item.id_actividad_fisica}</td>
                  <td>{item.actividad_fisica_ligera}</td>
                  <td>{item.actividad_fisica_moderada}</td>
                  <td>{item.actividad_fisica_intensa}</td>
                  <td>{item.gasto_energetico_basal}</td>
                  <td>{item.gasto_energetico_total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="form-buttons">
          <button type="button" onClick={() => navigate('/analisis_datos_v2')}>
            Volver
          </button>
        </div>
      </Container>
    </div>
  );
};

export default ConsultaActividadFisica;
