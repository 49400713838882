import React from 'react';
import './frontend_v2.css';
import Header_v2 from './header_v2';
import { Row } from 'antd';
import { Container } from 'react-bootstrap';
import Table from '@app/components/pages/sme/ComponentesSME/Table';

const CapturaSME: React.FC = () => {
  return (
    <div>
      <Header_v2 />
      <Container>
        <div className="green-bar">
          <Row>
            <h2 className="section-title">Captura SME</h2>
          </Row>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Table />
        </div>
      </Container>
    </div>
  );
};

export default CapturaSME;
