import React, { useState } from 'react';
import { Container, Card, Button, Form } from 'react-bootstrap';

const Antro_measures = () => {
  return (
    <div>
      <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
        <div style={{ maxWidth: '400px' }}>
          <Card className="d-flex align-items-center justify-content-center flex-column shadow-lg ">
            <Card.Body>
              <h2 className="text-center">Captura de datos</h2>
              <h6 className="text-center">Mediciones antropométricas</h6>

              <hr />
              <Form>
                <Form.Group id="gender">
                  <Form.Label>Genero</Form.Label>
                  <div className="d-flex justify-content-start">
                    <Form.Check type="radio" label="Masculino" name="gender" value="masculino" required />
                    <Form.Check
                      type="radio"
                      label="Femenino"
                      name="gender"
                      value="femenino"
                      className="femenino-label"
                      required
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-2" id="peso">
                  <Form.Label>Peso</Form.Label>
                  <Form.Control type="peso" min="1" max="400" placeholder="kg..." required />
                </Form.Group>
                <Form.Group className="mb-2 " id="altura">
                  <Form.Label>Estatura</Form.Label>
                  <Form.Control type="altura" placeholder="cm..." required />
                </Form.Group>
                <Form.Group className="mb-2 " id="imc">
                  <Form.Label>Índice de masa corporal (IMC)</Form.Label>
                  <Form.Control min="1" max="125" type="IMC" required />
                </Form.Group>
                <Form.Group className="mb-2 " id="c-cintura">
                  <Form.Label>Circunferencia de cintura</Form.Label>
                  <Form.Control min="1" max="200" type="CCintura" placeholder="cm..." required />
                </Form.Group>
                <Form.Group className="mb-2 " id="c-cadera">
                  <Form.Label>Circunferencia de cadera</Form.Label>
                  <Form.Control min="1" max="200" type="CCadera" placeholder="cm..." required />
                </Form.Group>
                <Form.Group className="mb-2 " id="icc">
                  <Form.Label>Índice cintura cadera (ICC)</Form.Label>
                  <Form.Control min="1" max="200" type="ICC" required />
                </Form.Group>
                <Form.Group className="mb-2 " id="grasa">
                  <Form.Label>Porcentaje de grasa</Form.Label>
                  <Form.Control min="1" max="100" type="grasa" placeholder="%" required />
                </Form.Group>
                <hr />
                <div className="text-center">
                  <Button className="w-75 mt-3 text-center" type="submit">
                    Enviar datos
                  </Button>
                  <div className="text-center">
                    <a href="/capturar_datos"> Volver </a>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default Antro_measures;
