import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Capturar_Datos from '../../inr_captura/capturar_datos';

export const ExampleComponent = () => {
  return (
    <div>
      <a href="/capturar_datos"> Capturar datos </a>
    </div>
  );
};
