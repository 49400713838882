import './menu_analisis_datos_v2.css';
import { Container, Row, Col, Button, CardGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Adn_icon from '../../../assets/icons/analisis_de_datos_adn.png';
import Biochemical_icon from '../../../assets/icons/analisis_de_datos_prueba-sangre.png';
import Antropometric_icon from '../../../assets/icons/analisis_de_datos_mediciones-antropometricas.png';
import Act_fisica_icon from '../../../assets/icons/analisis_de_datos_act-fisica.png';
import Alimentacion_icon from '../../../assets/icons/analisis_de_datos_alimentacion.png';
import Header_v2 from './header_v2';
import Card from 'react-bootstrap/Card';

const Menu_Analisis_Datos_v2 = () => {
  return (
    <div>
      <Header_v2 />

      <Container>
        <div className="green-bar">
          <Row>
            <h2 className="section-title">Analisis de datos</h2>
          </Row>
        </div>

        <div>
          <br />
          <CardGroup>
            <Card>
              <Card.Body>
                <Card.Title>Datos geneticos</Card.Title>
                <img className="adn-icon" src={Adn_icon} />
              </Card.Body>
              <Card.Footer>
                <Card.Link href="/consulta_datos_geneticos">Consulta</Card.Link>
                <Card.Link href="/captura_datos_geneticos">Captura (Médico)</Card.Link>
              </Card.Footer>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Datos bioquímicos</Card.Title>
                <img className="adn-icon" src={Biochemical_icon} />
              </Card.Body>
              <Card.Footer>
                <Card.Link href="/consulta_datos_bioquimicos">Consulta</Card.Link>
                <Card.Link href="/captura_datos_bioquimicos">Captura (Médico)</Card.Link>
              </Card.Footer>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Datos antropometricos</Card.Title>
                <img className="adn-icon" src={Antropometric_icon} />
              </Card.Body>
              <Card.Footer>
                <Card.Link href="/consulta_datos_antropometricos">Consulta</Card.Link>
                <Card.Link href="/captura_antropometrica">Capturar (Paciente)</Card.Link>
              </Card.Footer>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Actividad fisica</Card.Title>
                <img className="adn-icon" src={Act_fisica_icon} />
              </Card.Body>
              <Card.Footer>
                <Card.Link href="/consulta_actividad_fisica">Consulta</Card.Link>
                <Card.Link href="/calculadora">Capturar (Paciente)</Card.Link>
              </Card.Footer>
            </Card>
            <Card>
              <Card.Body>
                <Card.Title>Alimentación</Card.Title>
                <img className="adn-icon" src={Alimentacion_icon} />
              </Card.Body>
              <Card.Footer>
                <Card.Link href="/sme">Capturar</Card.Link>
              </Card.Footer>
            </Card>
          </CardGroup>
        </div>
      </Container>
    </div>
  );
};

export default Menu_Analisis_Datos_v2;
