import React from 'react';
import icon_Logo from '../../../assets/icons/Logo-icon.svg';
import { FaMapMarkerAlt, FaStethoscope, FaSearch, FaBell } from 'react-icons/fa';
import './header_v2.css';
import './frontend_v2.css';
import { Link } from 'react-router-dom';
import { ProfileDropdown } from '@app/components/header/components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { Col, Row } from 'antd';
import { HeaderFullscreen } from '@app/components/header/components/HeaderFullscreen/HeaderFullscreen';
import { NotificationsDropdown } from '@app/components/header/components/notificationsDropdown/NotificationsDropdown';
import { SettingsDropdown } from '@app/components/header/components/settingsDropdown/SettingsDropdown';

const Header_v2 = () => {
  return (
    <div className="Home">
      <Row align="middle" justify="end" gutter={[10, 10]}>
        <Col>
          <Row gutter={[{ xxl: 10 }, { xxl: 10 }]}>
            <Col>
              <HeaderFullscreen />
            </Col>

            <Col>
              <NotificationsDropdown />
            </Col>

            <Col>
              <SettingsDropdown />
            </Col>
          </Row>
        </Col>

        <Col>
          <ProfileDropdown />
        </Col>
      </Row>
      <div className="purple-bar"></div>
      <div className="search-location">
        <div className="icon-green">
          <FaMapMarkerAlt /> Jalisco, MX
        </div>
        <div className="search-div">
          <FaSearch />
          <input className="search-input" type="text" placeholder="Buscar..." />
        </div>
      </div>
      <header>
        <div className="logo">
          <div>
            <img className="img-icons" src={icon_Logo} alt="Icono 1" />
          </div>
          <div className="logo2">
            <h1>PredictGen</h1>
            <p>Examen y diagnóstico</p>
          </div>
        </div>
        <nav>
          <ul>
            <li>
              <Link to="/">
                <button>INICIO</button>
              </Link>
            </li>
            <li>
              <Link to="/acerca_de_v2">
                <button>ACERCA DE</button>
              </Link>
            </li>
            <li>
              <Link to="/analisis_datos_v2">
                <button>ANÁLISIS DE DATOS</button>
              </Link>
            </li>
            <li>
              <Link to="/nivel_riesgo">
                <button>NIVEL DE RIESGO</button>
              </Link>
            </li>
          </ul>
        </nav>
        <div className="user-profile">
          <FaBell />
        </div>
      </header>
    </div>
  );
};

export default Header_v2;
