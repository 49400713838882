import React, { useEffect, useState } from 'react';
import './frontend_v2.css';
import Header_v2 from './header_v2';
import { Row } from 'antd';
import { Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { notificationController } from '@app/controllers/notificationController';
import { Loading } from '@app/components/common/Loading';
import { isLoggin } from '@app/utils/localStorage.service';
import { Select, Option } from '@app/components/common/selects/Select/Select';
import { doConsultarDatosQuimicosDetalle } from '@app/api/slices/pacienteSlice';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { AnalisisQuimicoModel } from '@app/models/AnalisisQuimicoModel';
import { useParams } from 'react-router';

const formValues: AnalisisQuimicoModel = {
  id_paciente: '',
  id_medico: '',
  edad: '',
  fecha: '',
  direccion: '',
  identificacion: '',
  toma_muestra: '',
  genero: '',
  recepcion_muestra: '',
  leucocitos: '',
  neutrofilos: '',
  eosinofilos: '',
  linfocitos: '',
  monocitos: '',
  basofilos: '',
  hematies: '',
  hemoglobina: '',
  hematocrito: '',
  vcm: '',
  hcm: '',
  chcm: '',
  rdw: '',
  plaquetas: '',
  vpm: '',
  pct: '',
  idp: '',
  validado_por: '',
  quim_glucosa: '',
  quim_creatinina: '',
  quim_acido_urico: '',
  quim_colesterol: '',
  quim_ldl_colesterol: '',
  quim_hdl_colesterol: '',
  quim_trigliceridos: '',
  quim_alt_gpt: '',
  id_medicion_quimico: '',
  paciente: null,
  medico: null,
};

const ConsultaDatosQuimicosDetalle: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoadingPage, setLoadingPage] = useState(true);
  const { t } = useTranslation();
  const [medicion, setMedicion] = useState(formValues);
  const routeParams = useParams();

  useEffect(() => {
    if (isLoggin() != null) {
      dispatch(doConsultarDatosQuimicosDetalle(routeParams.id))
        .unwrap()
        .then((res: any) => {
          console.log(res.data);
          setMedicion(res.data);
          setLoadingPage(false);
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoadingPage(false);
        });
    } else {
      notificationController.error({ message: 'Es necesario iniciar sesion.' });
      navigate('/');
    }
  }, []);

  if (isLoadingPage) {
    return <Loading />;
  }

  return (
    <div>
      <Header_v2 />
      <Container>
        <div className="green-bar">
          <Row>
            <h2 className="section-title">Resultados bioquimicos</h2>
          </Row>
        </div>
        <br />
        <BaseForm layout="vertical" requiredMark="optional" initialValues={medicion}>
          <div className="div-table">
            <table className="results-table">
              <td colSpan={4} className="table-title">
                Resultados Bioquimicos
              </td>

              <tbody>
                <td colSpan={2}>Paciente:</td>
                <td colSpan={2}>
                  {medicion.paciente?.nombre} {medicion.paciente?.apellido_paterno}{' '}
                  {medicion.paciente?.apellido_materno}
                </td>
                <tr>
                  <td className="green-cell">Edad:</td>
                  <td>
                    <BaseForm.Item name="edad" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0" id="edad" step="1" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td className="green-cell">Fecha:</td>
                  <td>
                    <BaseForm.Item name="fecha" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="date" id="fecha" readOnly />
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr>
                  <td className="green-cell">Dirección:</td>
                  <td>
                    <BaseForm.Item name="direccion" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="text" placeholder="Direccion" id="direccion" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td className="green-cell">Identificación:</td>
                  <td>
                    <BaseForm.Item
                      name="identificacion"
                      rules={[{ required: true, message: t('common.requiredField') }]}
                    >
                      <input type="Text" placeholder="Identificación" id="identificacion" readOnly />
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr>
                  <td className="green-cell">Toma de muestra:</td>
                  <td>
                    <BaseForm.Item name="toma_muestra" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="Text" placeholder="Fecha y Hora" id="toma_muestra" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td className="green-cell">Género:</td>
                  <td>
                    <BaseForm.Item name="genero" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <Select width={400} disabled>
                        <Option key={'H'} value={'H'}>
                          Hombre
                        </Option>
                        <Option key={'M'} value={'M'}>
                          Mujer
                        </Option>
                      </Select>
                    </BaseForm.Item>
                  </td>
                </tr>
                <tr>
                  <td className="green-cell">Recepción de muestra:</td>
                  <td colSpan={3}>
                    <BaseForm.Item
                      name="recepcion_muestra"
                      rules={[{ required: true, message: t('common.requiredField') }]}
                    >
                      <input type="text" placeholder="Fecha y Hora" id="recepcion_muestra" readOnly />
                    </BaseForm.Item>
                  </td>
                </tr>

                <td colSpan={4} className="table-title">
                  Hematología
                </td>

                <tr className="no-border">
                  <th>EXAMEN</th>
                  <th>RESULTADO</th>
                  <th>UNIDAD</th>
                  <th>RANGO DE REFERENCIA</th>
                </tr>
                <tr>
                  <td colSpan={4} className="green-text">
                    BIOMETRÍA HEMÁTICA
                  </td>
                </tr>
                <tr className="no-border">
                  <td>LEUCOCITOS</td>
                  <td>
                    <BaseForm.Item name="leucocitos" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" id="leucocitos" step="0.01" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>K/ul</td>
                  <td>[4.50 - 11.00]</td>
                </tr>
                <tr className="no-border">
                  <td>NEUTRÓFILOS</td>
                  <td>
                    <BaseForm.Item name="neutrofilos" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="neutrofilos" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>%</td>
                  <td>[50.00 - 70.00]</td>
                </tr>
                <tr className="no-border">
                  <td>EOSINÓFILOS</td>
                  <td>
                    <BaseForm.Item name="eosinofilos" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="eosinofilos" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>%</td>
                  <td>[1.00 - 4.00]</td>
                </tr>
                <tr className="no-border">
                  <td>LINFOCITOS</td>
                  <td>
                    <BaseForm.Item name="linfocitos" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="linfocitos" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>%</td>
                  <td>[18.00 - 42.00]</td>
                </tr>
                <tr className="no-border">
                  <td>MONOCITOS</td>
                  <td>
                    <BaseForm.Item name="monocitos" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="monocitos" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>%</td>
                  <td>[2.00 - 8.00]</td>
                </tr>
                <tr className="no-border">
                  <td>BASÓFILOS</td>
                  <td>
                    <BaseForm.Item name="basofilos" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="basofilos" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>%</td>
                  <td>[0.50 - 1.00]</td>
                </tr>
                <tr className="no-border">
                  <td>HEMATÍES</td>
                  <td>
                    <BaseForm.Item name="hematies" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="hematies" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>/Mut</td>
                  <td>[4.50 - 5.90]</td>
                </tr>
                <tr className="no-border">
                  <td>HEMOGLOBINA</td>
                  <td>
                    <BaseForm.Item name="hemoglobina" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="hemoglobina" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>g/dL</td>
                  <td>[13.20 - 16.50]</td>
                </tr>
                <tr className="no-border">
                  <td>HEMATOCRITO</td>
                  <td>
                    <BaseForm.Item name="hematocrito" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="hematocrito" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>%</td>
                  <td>[42.00 - 52.00]</td>
                </tr>
                <tr className="no-border">
                  <td>VCM</td>
                  <td>
                    <BaseForm.Item name="vcm" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="vcm" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>fl</td>
                  <td>[80.00 - 100.00]</td>
                </tr>
                <tr className="no-border">
                  <td>HCM</td>
                  <td>
                    <BaseForm.Item name="hcm" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="hcm" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>pg</td>
                  <td>[28.00 - 32.80]</td>
                </tr>
                <tr className="no-border">
                  <td>CHCM</td>
                  <td>
                    <BaseForm.Item name="chcm" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="chcm" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>g/dL</td>
                  <td>[32.00 - 36.00]</td>
                </tr>
                <tr className="no-border">
                  <td>RDW</td>
                  <td>
                    <BaseForm.Item name="rdw" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="rdw" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>%</td>
                  <td>[11.50 - 14.50]</td>
                </tr>
                <tr className="no-border">
                  <td>PLAQUETAS</td>
                  <td>
                    <BaseForm.Item name="plaquetas" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="plaquetas" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>K/ul</td>
                  <td>[150.00 - 450.00]</td>
                </tr>
                <tr className="no-border">
                  <td>VPM</td>
                  <td>
                    <BaseForm.Item name="vpm" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="vpm" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>%</td>
                  <td>[7.40 - 10.40]</td>
                </tr>
                <tr className="no-border">
                  <td>PCT</td>
                  <td>
                    <BaseForm.Item name="pct" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="pct" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>%</td>
                  <td>[0.10 - 1.00]</td>
                </tr>
                <tr className="no-border">
                  <td>IDP</td>
                  <td>
                    <BaseForm.Item name="idp" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="idp" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>%</td>
                  <td>[11.00 - 18.00]</td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <BaseForm.Item name="validado_por" rules={[{ required: true, message: t('common.requiredField') }]}>
                      Validado por: <input type="text" placeholder="Validado por:" id="validado_por" readOnly />
                    </BaseForm.Item>
                  </td>
                </tr>

                <td colSpan={4} className="table-title">
                  Química Sanguínea
                </td>

                <tr className="no-border">
                  <th>EXAMEN</th>
                  <th>RESULTADO</th>
                  <th>UNIDAD</th>
                  <th>RANGO DE REFERENCIA</th>
                </tr>
                <tr className="no-border">
                  <td>GLUCOSA</td>
                  <td>
                    <BaseForm.Item name="quim_glucosa" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="quim_glucosa" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>mg/dL</td>
                  <td>[70.00 - 100.00]</td>
                </tr>
                <tr className="no-border">
                  <td>CREATININA</td>
                  <td>
                    <BaseForm.Item
                      name="quim_creatinina"
                      rules={[{ required: true, message: t('common.requiredField') }]}
                    >
                      <input type="number" placeholder="0.00" step="0.01" id="quim_creatinina" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>mg/dL</td>
                  <td>[0.80 - 1.30]</td>
                </tr>
                <tr className="no-border">
                  <td>ÁCIDO ÚRICO</td>
                  <td>
                    <BaseForm.Item
                      name="quim_acido_urico"
                      rules={[{ required: true, message: t('common.requiredField') }]}
                    >
                      <input type="number" placeholder="0.00" step="0.01" id="quim_acido_urico" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>mg/dL</td>
                  <td>[3.50 - 7.20]</td>
                </tr>
                <tr className="no-border">
                  <td>COLESTEROL</td>
                  <td>
                    <BaseForm.Item
                      name="quim_colesterol"
                      rules={[{ required: true, message: t('common.requiredField') }]}
                    >
                      <input type="number" placeholder="0.00" step="0.01" id="quim_colesterol" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>mg/dL</td>
                  <td>CONVENIENTE [0.00 - 200.00] LÍMITE ELEVADO [200.00 - 239.00] ELEVADO 240.00</td>
                </tr>
                <tr className="no-border">
                  <td>LDL Colesterol</td>
                  <td>
                    <BaseForm.Item
                      name="quim_ldl_colesterol"
                      rules={[{ required: true, message: t('common.requiredField') }]}
                    >
                      <input type="number" placeholder="0.00" step="0.01" id="quim_ldl_colesterol" readOnly />
                    </BaseForm.Item>{' '}
                  </td>
                  <td>mg/dL</td>
                  <td>[70.00 - 100.00]</td>
                </tr>
                <tr className="no-border">
                  <td>HDL Colesterol</td>
                  <td>
                    <BaseForm.Item
                      name="quim_hdl_colesterol"
                      rules={[{ required: true, message: t('common.requiredField') }]}
                    >
                      <input type="number" placeholder="0.00" step="0.01" id="quim_hdl_colesterol" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>mg/dL</td>
                  <td>[70.00 - 100.00]</td>
                </tr>
                <tr className="no-border">
                  <td>Trigliceridos</td>
                  <td>
                    <BaseForm.Item
                      name="quim_trigliceridos"
                      rules={[{ required: true, message: t('common.requiredField') }]}
                    >
                      <input type="number" placeholder="0.00" step="0.01" id="quim_trigliceridos" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>mg/dL</td>
                  <td>[0.80 - 1.30]</td>
                </tr>
                <tr className="no-border">
                  <td>AST (GOT)</td>
                  <td>
                    <BaseForm.Item name="quim_ast" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="quim_ast" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>mg/dL</td>
                  <td>[3.50 - 7.20]</td>
                </tr>
                <tr className="no-border">
                  <td>ALT (GPT)</td>
                  <td>
                    <BaseForm.Item name="quim_alt_gpt" rules={[{ required: true, message: t('common.requiredField') }]}>
                      <input type="number" placeholder="0.00" step="0.01" id="quim_alt_gpt" readOnly />
                    </BaseForm.Item>
                  </td>
                  <td>mg/dL</td>
                  <td>CONVENIENTE [0.00 - 200.00] LÍMITE ELEVADO [200.00 - 239.00] ELEVADO 240.00</td>
                </tr>
              </tbody>
            </table>
            <div className="form-buttons">
              <Link to="/analisis_datos_v2">
                <button type="button">Volver</button>
              </Link>
              <button type="button" onClick={() => window.print()}>
                Imprimir
              </button>
            </div>
          </div>
        </BaseForm>
      </Container>
    </div>
  );
};

export default ConsultaDatosQuimicosDetalle;
