import React from 'react';
import icon_Estetoscopio from '../../../assets/icons/Estetoscopio-icon.png';
import icon_Lupa from '../../../assets/icons/Lupa-icon.png';
import './frontend_v2.css';
import Header_v2 from './header_v2';

const Pag_Inicio_v2 = () => {
  return (
    <div className="Home">
      <Header_v2 />
      <main>
        <h2>Cuidando de tu salud</h2>
        <div className="services">
          <div className="service">
            <div className="icons">
              <img className="img-icons" src={icon_Lupa} alt="Icono 2" />
            </div>
            <h3>Análisis genético y nutricional</h3>
            <p>Diagnóstico integral del estado de salud</p>
          </div>
          <div className="service">
            <div className="icons">
              <img className="img-icons" src={icon_Estetoscopio} alt="Icono 3" />
            </div>
            <h3>Atención personalizada</h3>
            <p>Consultas adaptadas a tus características individuales</p>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Pag_Inicio_v2;
