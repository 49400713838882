import React, { useState } from 'react';
import { Container, Card, Button, Form } from 'react-bootstrap';

const Sleep_cycle = () => {
  return (
    <div>
      <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
        <div style={{ maxWidth: '400px' }}>
          <Card className="d-flex align-items-center justify-content-center flex-column shadow-lg ">
            <Card.Body>
              <h2 className="text-center">Captura de datos</h2>
              <h6 className="text-center">Ciclo de sueño</h6>

              <hr />
              <Form>
                <Form.Group className="mb-2" id="fecha">
                  <Form.Label>Fecha</Form.Label>
                  <Form.Control type="fecha" placeholder="dd-mm-aaaa" required />
                </Form.Group>
                <Form.Group className="mb-2 " id="tiemposueno">
                  <Form.Label>Horas de sueño</Form.Label>
                  <Form.Control type="Tiemposueno" placeholder="h..." required />
                </Form.Group>
                <hr />
                <div className="text-center">
                  <Button className="w-75 mt-3 text-center" type="submit">
                    Enviar datos
                  </Button>
                  <div className="text-center">
                    <a href="/capturar_datos"> Volver </a>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default Sleep_cycle;
