import React from 'react';
import './frontend_v2.css';
import Header_v2 from './header_v2';
import { Row } from 'antd';
import { Container } from 'react-bootstrap';
import Calculadora from '../calculadora/calculadora';

const CapturaCalculadora: React.FC = () => {
  return (
    <div>
      <Header_v2 />
      <Container>
        <div className="green-bar">
          <Row>
            <h2 className="section-title">Captura Gasto Energético Basal y Total</h2>
          </Row>
        </div>
        <Calculadora />
      </Container>
    </div>
  );
};

export default CapturaCalculadora;
