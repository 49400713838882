import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doSignUp } from '@app/api/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './RegistroForm.styles';
import { RadioGroup } from '@app/components/common/Radio/Radio';
import RadioButton from 'antd/lib/radio/radioButton';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';

interface SignUpFormData {
  tipoUsuario: string;
  correo: string;
  password: string;
}

const initValues = {
  firstName: '',
  lastName: 'Johnson',
  email: '',
  password: '',
  confirmPassword: '',
  termOfUse: false,
  tipoUsuario: '',
};

export const RegistroForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isMedico, setMedico] = useState(true);
  const [aceptoTerminos, setAceptoTerminos] = useState(true);

  const { t } = useTranslation();

  const setMedicoFunction = (isMed: boolean) => {
    setMedico(isMed);
  };
  const handleChange = () => {
    setAceptoTerminos(!aceptoTerminos);
  };
  const handleSubmit = (values: SignUpFormData) => {
    if (isMedico) {
      values.tipoUsuario = 'MEDICO';
    } else {
      values.tipoUsuario = 'PACIENTE';
    }
    setLoading(true);
    dispatch(doSignUp(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: t('Registro exit�so'),
          description: t('Se ha enviado un correo con su codigo de activaci�n.'),
        });
        navigate('/auth/confirmar-registro');
      })
      .catch((err) => {
        console.log(err);
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <S.Title>
          <h1>Registro</h1>
        </S.Title>
        <Auth.FormItem>
          <Auth.SubmitButton
            type="primary"
            htmlType="button"
            disabled={!isMedico}
            onClick={() => setMedicoFunction(false)}
          >
            {t('Medico')}
          </Auth.SubmitButton>
          <hr></hr>
          <Auth.SubmitButton
            type="primary"
            htmlType="button"
            disabled={isMedico}
            onClick={() => setMedicoFunction(true)}
          >
            {t('Paciente')}
          </Auth.SubmitButton>
        </Auth.FormItem>
        <Auth.FormItem
          name="correo"
          label={t('Correo')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('Correo electrónico')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('Contraseña')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('Confirma tu contraseña')}
          name="confirmPassword"
          dependencies={['password']}
          rules={[
            { required: true, message: t('common.requiredField') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('common.confirmPasswordError')));
              },
            }),
          ]}
        >
          <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="termOfUse" valuePropName="checked" noStyle>
            <Auth.FormCheckbox onChange={handleChange}>
              <Auth.Text>{t('Acepto los términos y condiciones')} </Auth.Text>
            </Auth.FormCheckbox>
          </BaseForm.Item>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading} disabled={aceptoTerminos}>
            {t('Registrarme')}
          </Auth.SubmitButton>
        </BaseForm.Item>
        <Auth.FooterWrapper>
          <Auth.Text>
            {t('Ya tienes cuenta? Ingresa')}{' '}
            <Link to="/auth/login">
              <Auth.LinkText>{t('aqui')}</Auth.LinkText>
            </Link>
          </Auth.Text>
        </Auth.FooterWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
