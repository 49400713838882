import React from 'react';
import './frontend_v2.css';
import Header_v2 from './header_v2';

const Captura_Act_Fisica = () => {
  return (
    <div>
      <Header_v2 />
      <div className="CapFis">
        <div className="green-bar">
          <h2 className="h2-nav">Captura de datos actividad física</h2>
        </div>
        <div className="form-container">
          <div className="form-column">
            <label htmlFor="peso">Peso (kg)</label>
            <input type="text" id="peso" name="peso" placeholder="Ingrese su peso (kg)" />

            <label htmlFor="estatura">Estatura (cm)</label>
            <input type="text" id="estatura" name="estatura" placeholder="Ingrese su estatura (cm)" />

            <label htmlFor="imc">Edad</label>
            <input type="text" id="imc" name="imc" placeholder="Ingrese su Edad" />
          </div>

          <div className="form-column">
            <label htmlFor="grasa">Porcentaje de grasa (%)</label>
            <input type="text" id="grasa" name="grasa" placeholder="Ingrese su porcentaje de grasa (%)" />

            <label htmlFor="cadera">Actividad física</label>
            <input type="text" id="cadera" name="cadera" placeholder="Sedentraio (Trabajo de oficina)" />
          </div>

          <div className="form-buttons">
            <button type="button">Volver</button>
            <button type="submit">Capturar datos</button>
          </div>
        </div>

        <div className="purple-bar-bottom" />
      </div>
    </div>
  );
};

export default Captura_Act_Fisica;
